import React from "react"
import "../../assets/index.css"
import Layout from "../../layouts/layout"
import Hero from "../../components/hero-policy";

export default () => (
    <Layout  title="Privacy Statement | Ladd Partners">
        <Hero></Hero>
    </Layout>
)
