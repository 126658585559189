import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const Flex = styled.div `
    max-width: 1110px;
    margin: 81px auto 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 81px;

    @media only screen and (max-width: 1120px) {
        padding: 0 20px;
    }

    @media only screen and (max-width: 767px) {
        margin-top: 33px;
        display: none;
    }
`

const FlexBody = styled.div `
    max-width: 1110px;
    margin: 81px auto 0px;
    margin-top: 81px;
    margin-bottom: 80px;

    @media only screen and (max-width: 1120px) {
        padding: 0 20px;
    }
`

const HeroTextDiv = styled.div `
    width: 100%;
`;

const HeroDiv = styled(BackgroundImage)`
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center bottom;
    background-color: #FFF;
    height: 240px;
    margin-top: 80px;

    @media only screen and (max-width: 767px) {
        height: 190px
    }
`;

const query = graphql`
    query {
        sanityHero(title: { eq: "Privacy Statement" }) {
            title
           heading
            subline
            video_url
            background_color
            image {
                asset {
                    url
                }
            }
            background_image {
                asset {
                    fluid(maxWidth: 1920) {
                        ...GatsbySanityImageFluid
                    }
                }
              }
          }
      }
  `

function createMarkup(data) { return {__html: data}; };

export default () => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <>
                <HeroDiv fluid={data.sanityHero.background_image.asset.fluid}>
                    <Flex>
                    <HeroTextDiv className="hero">
                        <div dangerouslySetInnerHTML={createMarkup(data.sanityHero.heading)}/>
                    </HeroTextDiv>
                    </Flex>
                </HeroDiv>
                
                <FlexBody>
                    <p><strong>Ladd Partners Privacy Statement &ndash; updated 31 March 2023</strong></p>
                    <p>
                        Ladd Partners, LLC and Ladd Software Development, Inc (&ldquo;Ladd Partners&rdquo;, &ldquo;LP&rdquo;, &ldquo;We&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;) work closely with our clients and respect the privacy of everyone we work with.
                        Please read this Privacy Statement, also referred to as our Privacy Policy, to learn more about the ways in which we use and protect the personal information that is provided through our relationship.
                    </p>
                    <p>
                        This Privacy Policy reflects LP&rsquo;s compliance with General Data Protection Regulation (GDPR) in the European Union for our EEA based relationships. You can always reference our contact information, below, if you have any questions
                        or concerns. Key aspects are:
                    </p>
                    <ol>
                        <li aria-level="1"><span><strong>Readability and Transparency:</strong> LP tries to provide simple language around the personal information we collect from our clients, why we collect such information, and how we use such information.</span></li>
                        <li aria-level="1"><span><strong>Control over your data:</strong> you always have a choice in how we utilize your data and we urge you to exercise your right to correct or remove any information that we maintain about your company.</span></li>
                    </ol>
                    <p>Except as otherwise stated in this Privacy Policy, we do not sell, rent, or release for free any information about you or your company of employment to third parties.</p>
                    <p> This Privacy Policy describes the choices you can make about how Ladd Partners collects and uses your information. You may opt out of any information sharing by contacting us in accordance with this policy.</p> 
                    
                    <p>To do so, please contact us: email <a href="mailto:privacymatters@laddpartners.com">privacymatters@laddpartners.com</a>; or write to us at:</p>
                    <p><strong>Ladd Partners</strong><br/>
                    <span>Attn: Privacy Concerns</span><br/>
                    <span>PO Box 8593</span><br/>
                    <span>San Jose, CA&nbsp; 95155&nbsp;</span><br/>
                    <span>United States of America</span><br/> </p>
                    <p>For companies based in the European Economic Area (UK, EU, EEA) you can refer to our contact information, above, if you have any concerns regarding your privacy rights.</p>
                    
                    <p><strong>Modifications to this Privacy Policy</strong></p>
                    <p>
                        If our data-related practices change in the future, we will post updates on this website. See the revision date that appears near the top of this policy. Where required by law, we will seek your consent to any material changes made to
                        our Privacy Policy. Please refer to the contact information above if you wish to exercise your rights.
                    </p>
                    <h3>How does Ladd Partners collect and use data about our clients?</h3>
                    <p>We collect information about your company if you:</p>
                    <ol>
                        <li aria-level="1"><span>hire LP to engage in any of our consulting services;</span></li>
                        <li aria-level="1"><span>purchase LP software applications;</span></li>
                        <li aria-level="1"><span>use our website;</span></li>
                        <li aria-level="1"><span>if you communicate directly with Ladd Partners</span></li>
                    </ol>
                    <p>
                        The data provided to LP is used to execute the services we offer, verify and carry out financial transactions, and to fulfill our legal obligations. This often entails gaining administrator-level access to multiple systems that are used
                        to operate your business. We understand that this means we may have full access to all data, sensitive and non-sensitive in nature. We use this information solely for the purposes of carrying out the services that you have hired Ladd
                        Partners to perform. We take the fact that we have access to your data, as it is stored in your company&rsquo;s systems, very seriously. We may collect and store the following about the people that are employed at your company: first
                        and last name, email address, and titles and/or roles. We collect and store this information solely to maintain communications with active employees within your company.
                    </p>
                    <p>Information that is provided to Ladd Partners about your business and its employees may be used to conduct investigations and report abuse in order to comply with our legal obligations.</p>
                    
                    <p><strong>Use of our Website</strong></p>
                    <p>
                        When using our website, we collect the following data that results from your usage of the website: date and time of access, referral page, IP-address, browser type, language and version of browser software, geographic location
                        (determined by your IP address), operating system.
                    </p>
                    <p>
                        Our website offers what is commonly referred to as a &ldquo;web-to-lead&rdquo; form in our Contact Us section of the website. If you choose to submit this form, you will be asked to provide the following personal information about you:
                        first and last name, email address, and place of employment.
                    </p>
                    <p>We will process your personal information in order to respond to your requests, verify the legitimacy of your request, and comply with legal and contractual obligations, and defend, establish and exercise legal claims.</p>
                   
                    <p><strong>Lawful bases</strong></p>
                    <p>
                        Where GDPR applies to how or why we process your personal information, we will only collect and process any personal information where we are satisfied that there is a lawful basis to do so. We carry out the processing of personal
                        information on the following lawful bases:
                    </p>
                    <ul>
                        <li aria-level="1"><span>processing is necessary to perform within the bounds of a contract to which you are a party;</span></li>
                        <li aria-level="1">
                            <span>
                                processing is necessary for compliance with a legal obligation to which we are subject to comply. For example, in order to provide our consulting services we are obliged to carry out certain know-your-customer checks to prevent
                                fraudulent activities or engagements with fraudulent entities. This will involve the collection and verification of personal information and business representatives directly related to your company;
                            </span>
                        </li>
                        <li aria-level="1"><span>you have provided your explicit consent to receive communications or important updates about industry trends or innovations. We will send you such communications on the basis of your agreement;</span></li>
                        <li aria-level="1">
                            <span>processing is necessary for the purposes of protecting the vital interests of a data subject or the legitimate interests pursued by us, except where such interests are overridden by fundamental rights and freedoms.</span>
                        </li>
                    </ul>
                    <p>
                        Unless otherwise specified, not providing your personal information will not result in legal consequences to you. You are always welcome to contact us about the lawful basis for which we process personal information. Refer to our
                        contact information above.
                    </p>
                    <p>
                        Ladd Partners contracts with third party technology vendors to help us execute our services and our business operations, to process client information in order to fulfill contractual obligations and to communicate with our clients. We
                        take much care in choosing the technology providers that we use to store and process your information including, but not limited to, Google (Workspace), Salesforce.com, 1Password for Business, Atlassian (Jira, Bitbucket, and
                        Confluence). Ladd Partners does not authorize any third party provider to make any other use of your information.
                    </p>
                    <p>
                        We limit employee access to personal information to those who have a business reason to know this information. We believe your data must be protected against loss and unauthorized access. We use procedural and technical safeguards
                        provided to us by the vendors in which we choose to store your data including encryption, firewalls, and secure communication protocols to protect your personal information against loss, theft, accidental, and unauthorized access,
                        erasure, use, processing, and disclosure.
                    </p>
                    <p>
                        As the security of information depends in part on the security of the device you use to communicate with us and the security you use to protect user usernames and passwords, please take appropriate measures to protect this information.
                    </p>
                    <h3>How long is my information stored for?</h3>
                    <p>
                        Personal information about your or the company for which you are employed will be retained as long as it is required for the purposes for which it was collected e.g. as necessary to provide the services requested. Once our contractual
                        relationship with you comes to an end or in case of the service recipient, following the termination of the contractual relationship for which your personal information was processed, we will delete the personal information unless
                        statutory retention requirements apply e.g. for taxation purposes or in order to fulfill regulatory requirements. We retain your contact details and interests in our services for a longer period of time if we are allowed to send you
                        salient communications. We also retain your personal information if needed to establish, exercise or defend a claim.
                    </p>
                    <p><strong>Cookies and similar technologies</strong></p>
                    <p>
                        Cookies are small text files that are often placed on your computer&rsquo;s file system to store data that can be recalled by a server in the domain that stored the cookie. We use cookies and similar technologies on our sites in order
                        to improve your online experience. Through our use of cookies, we also track and maintain the identity of the website you visited immediately prior to visiting our website. We do not otherwise track any information about your use of
                        other websites.
                    </p>
                    <p>
                        You have a variety of tools to control the data collected by cookies and similar technologies. For example, you can use the controls in your internet browser to limit how the websites you visit are able to use cookies and to withdraw
                        your consent by clearing or blocking cookies.
                    </p>
                    <p><strong>Reporting Requirements</strong></p>
                    <p>
                        In the unfortunate event we become aware of any unauthorized access or use of personal information stored by us as a result of actions taken by Ladd Partners or any outside party, we will report this to the appropriate authorities, as
                        required by law. These actions might result in the direct notification to any individual whose personal information is involved in the incident. The exact steps taken to report and notify of any such event will be guided by paid, third
                        party, legal services where deemed necessary or prudent.
                    </p>
                    <p>Rights applicable to European Union, UK, and California Data Subjects</p>
                    <p>European Union, UK, and California data subjects have further rights in addition to those mentioned above:</p>
                    <ol>
                        <li>
                            <strong>Right of access:</strong> you have the right to obtain from us confirmation as to whether or not personal information concerning you, or (for parents / guardians) the Service User, is being processed, and where that is the case, to request
                            access to the personal information. The accessed information includes &ndash; among others &ndash; the purposes of the processing, the categories of personal information concerned, and the recipients or categories of recipients to
                            whom the personal information have been or will be disclosed. You have the right to obtain a copy of the personal information undergoing processing. For further copies requested by you, we may charge a reasonable fee based on
                            administrative costs.
                        </li>
                        <li>
                            <strong>Right to rectify and complete personal information:</strong> you can request the rectification of inaccurate data and the completion of incomplete data. We will inform relevant third parties to whom we have transferred your data about the
                            rectification and completion if we are legally obliged to do so.
                        </li>
                        <li>
                            <strong>Right to erasure (right to be forgotten):</strong> You have the right to obtain from us the erasure of personal information concerning you in limited circumstances where:
                            <ol>
                                <li aria-level="3"><span>compliance with a legal obligation; or</span></li>
                                <li aria-level="3"><span>the establishment, exercise or defense of legal claims.</span></li>
                            </ol>
                        </li>
                            <ol type="a">
                                <li aria-level="2"><span>it is no longer needed for the purposes for which it was collected; or</span></li>
                                <li aria-level="2"><span>you have withdrawn your consent (where the data processing was based on consent); or</span></li>
                                <li aria-level="2"><span>following a successful right to object; or</span></li>
                                <li aria-level="2"><span>it has been processed unlawfully; or</span></li>
                                <li aria-level="2">
                                    <span>
                                        the data has to be erased in order to comply with a legal obligation to which Ladd Partners is subject. We are not required to comply with your request to erase personal information if the processing of your personal
                                        information is necessary for:
                                    </span>
                                </li>
                            </ol>
                        <li>
                            <strong>Right to restriction of processing:</strong> You have the right to request restrictions to the processing of your personal information. In this case, the respective data will be marked and can only be processed by us for certain
                            purposes. This right can only be exercised where:
                        </li>
                        <ol type="a">
                            <li aria-level="2"><span>the accuracy of your personal information is contested, to allow us to verify its accuracy; or</span></li>
                            <li aria-level="2"><span>the processing is unlawful, but you do not want the personal information erased; or</span></li>
                            <li aria-level="2">
                                <span>
                                    it is no longer needed for the purposes for which it was collected, but you still need it to establish, exercise or defend legal claims; or you have exercised the right to object, and verification of overriding grounds is
                                    pending. We can continue to use your personal information following a request for restriction, where:
                                </span>
                                <ol type="i">
                                <li aria-level="3"><span>we have your consent; or</span></li>
                                <li aria-level="3"><span>to establish, exercise or defend legal claims; or</span></li>
                                <li aria-level="3"><span>to protect the rights of another natural or legal person.</span></li>
                            </ol>
                            </li>
                        </ol>
                        <li>
                            <span>
                              <strong>Right to data portability:</strong> You have the right to receive the personal information concerning you, or (in relation to parents/guardians) the Service User, which you have provided to us, in a structured, commonly used and
                                machine-readable format and you have the right to transmit those data to another entity without hindrance from us, but in each case only where:
                            </span>
                        </li>
                        <ol type="a">
                            <li aria-level="2"><span>the processing is based on your consent or on the performance of a contract with you; and</span></li>
                            <li aria-level="2"><span>the processing is carried out by automated means.</span></li>
                        </ol>
                        <li>
                            <span>
                                <strong>Right to object:</strong> you have a right to object at any time to the processing of your personal information which has our legitimate interests as its lawful basis. If you raise an objection, we have an opportunity to demonstrate that
                                we have compelling legitimate interests which override your rights and freedoms. The right to object does not exist, in particular, if the processing of your personal information is necessary to take steps prior to entering into
                                a contract or to perform a contract already concluded.
                            </span>
                        </li>
                        <li>
                            <span>
                                <strong>Right to object to how we use your personal information for direct marketing purposes:</strong> you can request that we change the manner in which we contact you for marketing purposes. You can request that we not transfer your personal
                                information to unaffiliated third parties for the purposes of direct marketing or any other purposes.
                            </span>
                        </li>
                        <li>
                            <span>
                                <strong>Right to withdraw consent:</strong> if you have given us your consent for the processing of your personal information, or (as a parent/guardian) the information of a Service User, you have the right to withdraw your consent at any time,
                                without affecting the lawfulness of processing based on consent before its withdrawal.
                            </span>
                        </li>
                        <li>
                            <span>
                                <strong>Right to obtain a copy of personal information safeguards for transfer of data outside your jurisdiction:</strong> if you are a resident or citizen of the EU or UK (as applicable), you can ask to obtain a copy of, or reference to, the
                                safeguards under which your personal information is transferred outside the EU or the UK (as applicable). We may redact data transfer agreements to protect commercial terms.
                            </span>
                        </li>
                        <li>
                            <span>
                                <strong>Right to lodge a complaint with your local data protection authority:</strong> if you have concerns about how we process your personal information, you have a right, at any time, to lodge a complaint with your local data protection
                                supervisory authority (i.e. your place of habitual residence, place or work or place of alleged infringement).
                            </span>
                        </li>
                    </ol>
                </FlexBody>
                </>
            )
        }}
    />
)